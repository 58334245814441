import { render, staticRenderFns } from "./ReNewalHomeEn.vue?vue&type=template&id=86d47d56&scoped=true"
import script from "./ReNewalHomeEn.vue?vue&type=script&lang=js"
export * from "./ReNewalHomeEn.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/home/homefont.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./ReNewalHomeEn.vue?vue&type=style&index=1&id=86d47d56&prod&lang=css"
import style2 from "@/assets/css/home/renewalhome.css?vue&type=style&index=2&id=86d47d56&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d47d56",
  null
  
)

export default component.exports