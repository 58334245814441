<template>
    <div id="homeContainer" class="home_container">
        <!-- Modal -->
        <home-modal
            v-if="isOpenHomeModal"
            @close-modal="onCloseHomeModal"
            :isen="true"
            :nameplaceholder="'Name'"
            :companyplaceholder="'Company'"
            :emailplaceholder="'Email'"
            :phoneplaceholder="'Phone number'"
            :titleplaceholder="'Inquiry title'"
            :contentplaceholder="'Inquiry details'"
        >
            <template #headertitle>
                Inquiry
            </template>

            <template #modaltabbtn1>
                Partnership inquiry
            </template>

            <template #modaltabbtn2>
                1:1 inquiry
            </template>

            <template #checktext>
                I agree to the collection and use of my personal information.
            </template>

            <template #checkagreemodal>
                See detail
            </template>

            <template #contactbtn>
                Confirm
            </template>

            <template #completetext>
                Your inquiry has been received. <br />
                We will reach you as soon as possible.
            </template>
        </home-modal>
        <!--Header-->
        <home-header
            :activeindex="activeIndex"
            :isshownews="false"
            :isshowexperience="false"
            :islogin="false"
            :isactivelanguage="'en'"
            @open-inquiry="onOpenInquiry"
            @click-nav="onClickNav"
            @click-gopage="onClickGoPage"
        >
            <template #firstbtn>
                Company
            </template>

            <template #secondtbtn>
                Technology
            </template>

            <template #thirdtbtn>
                Product
            </template>

            <template #fourthtbtn>
                Client
            </template>

            <template #inquirybtn>
                Inquiry
            </template>
        </home-header>
        <section class="home_section1">
            <home-section1>
                <template #hometitle1>
                    <figure class="home_title_logo_wrap en">
                        <img
                            class="dp_inlineblock home_title_logo en"
                            src="/media/images/ev_main_con01_logo.png"
                            alt="ev_main_con01_logo"
                        />
                    </figure>
                    <span class="home_title1_text en">every space</span> <br class="home_title1_text_br" />
                    <span class="home_title1_text en"> in the world!</span>
                </template>
            </home-section1>
        </section>
        <section class="home_section2">
            <home-section2>
                <template #homesection2title>
                    The power of music becomes more amazing as you get to know more.
                </template>

                <template #homesection2subtitle>
                    Music moves emotions, sets the mood, and shapes experiences and memories.
                </template>

                <template #homesection2imgtextfirst>
                    <p class="home_section2_img_text first">
                        <strong class="home_section2_img_text_strong">77% </strong>of customers say that optimal music positively
                        influences their decision to visit a store
                    </p>
                </template>

                <template #homesection2imgtextsecond>
                    <p class="home_section2_img_text">
                        Music suitable for the store can lead to a
                        <strong class="home_section2_img_text_strong"> 37.1% </strong>increase in sales compared to regular music
                    </p>
                </template>

                <template #homesection2imgtextthird>
                    <p class="home_section2_img_text">
                        Playing music that matches the atmosphere of the space can increase time of stay at the store by
                        <strong class="home_section2_img_text_strong">35%</strong>
                    </p>
                </template>

                <template #homesection2paragraph>
                    &lt;Source&gt; <br />
                    Mood Media <br />
                    Milliman, R.E. Using Background Music to Affect the Behavior of Supermarket Shoppers. Journal of Marketing
                    <br />
                    Spotify Research Analysis of the Impact of In-Store Music on Sales
                </template>
            </home-section2>
        </section>
        <section class="home_section3" id="homeSection3">
            <home-section3 :isen="true">
                <template #home3sectiontitle>
                    A Revolutionary
                    <div class="home3_section_title_br"></div>
                    AI-Powered Spatial Music Service
                </template>

                <template #home3sectionparagraph>
                    Utilizing diverse data of various types of spaces, situations, purposes, and brands,
                    <div class="home3_section_paragraph2_br"></div>
                    our AI Curation Engine matches and recommends music in real-time.
                </template>
                <template #home3sectionparagraphmobile>
                    Utilizing diverse data of various types of spaces, situations, purposes, and brands,
                    <div class="home3_section_paragraph2_br"></div>
                    our AI Curation Engine matches and recommends music in real-time.
                </template>

                <template #sectioncontents1>
                    <img class="w_100" src="/media/images/home/section_img1pc_en.png" alt="공간데이터/음악데이터" />
                </template>
                <template #sectioncontents1tablet>
                    <img
                        class="home3_section_contents_tablet_img w_100"
                        src="/media/images/home/section_img1tablet_en.png"
                        alt="공간데이터/음악데이터"
                    />
                </template>

                <template #sectioncontents1mobile>
                    <img
                        class="home3_section_contents_mobile_img w_100"
                        src="/media/images/home/section_img1mobile_en.png"
                        alt="공간데이터/음악데이터"
                    />
                </template>

                <template #home3sectioncontents1itemfirst>
                    <div class="home3_section_contents1_item en">
                        Spatial data
                    </div>
                </template>
                <template #home3sectioncontents1itemsecond>
                    <div class="home3_section_contents1_item second en">
                        Music data
                    </div>
                </template>

                <template #sectioncontents3text>
                    <p class="section_contents3_text en">
                        Optimal music <br />
                        curation for space
                    </p>
                </template>

                <template #home3sectiontab1>
                    Spatial data
                </template>

                <template #home3sectiontab2>
                    Music data
                </template>

                <template #tab_cotents_text1>
                    Real-time data is collected through H/W, external data sources, and basic store data. <br />
                    The collected data is then analyzed and processed for utilization.
                </template>

                <template #tab_cotents_text1_tablet>
                    Real-time data is collected through H/W, <br />
                    external data sources,and basic store data.<br />
                    The collected data is then analyzed and processed for utilization.
                </template>

                <template #tab_cotents_text1_mobile>
                    Real-time data is collected through H/W, <br />
                    external data sources,and basic store data. <br />
                    The collected data is then analyzed and processed for utilization.
                </template>

                <template #tab_cotents_text2>
                    Music tracks are labeled through an automatic labeling system based on spatial data, <br />
                    allowing for the recommendation of the most suitable music for each space based on this data.
                </template>

                <template #tab_cotents_text2_tablet>
                    Music tracks are labeled through an automatic labeling system based on spatial data, allowing for the
                    recommendation of the most suitable music for each space based on this data.
                </template>

                <template #tab_cotents_text2_mobile>
                    Music tracks are labeled through an automatic labeling system based on spatial data, allowing for the
                    recommendation of the most suitable music for each space based on this data.
                </template>

                <template #tab_sub1tab_headtext1>
                    <span class="tab_sub1tab_headtext en">
                        Data Collection
                    </span>
                </template>

                <template #tab2subtab2contentsimg1>
                    <figure class="tab2_subtab2_contents_wrap flex_justify_center">
                        <img
                            class="tab2_subtab2_contents_img en"
                            src="/media/images/home/tab2_subtab2_contents_en.png"
                            alt="tab2_subtab2_contents.png"
                        />
                    </figure>
                </template>

                <template #tab2sub1tabtabletimg1>
                    <div class="tab2_sub1tab_img1_tabletwrap">
                        <img
                            class="tab2_sub1tab_img1_tablet"
                            src="/media/images/home/tab2_subtab2_contents_en.png"
                            alt="tab1_sub1tab_img_tablet"
                        />
                    </div>
                </template>

                <template #tab_sub1tab_headtext2>
                    <span class="tab_sub1tab_headtext en">
                        Data analysis/processing
                    </span>
                </template>

                <template #tab1_sub2tab_item1>
                    <li class="tab1_sub2tab_item flex_justify_center en">
                        Age, Gender, Congestion
                    </li>
                </template>

                <template #tab1_sub2tab_item2>
                    <li class="tab1_sub2tab_item flex_justify_center en">
                        Genre, Era, Feedback
                    </li>
                </template>

                <template #tab1_sub2tab_item3>
                    <li class="tab1_sub2tab_item flex_justify_center en">
                        Weather, Time of day, Season
                    </li>
                </template>

                <template #tab1_sub2tab_item4>
                    <li class="tab1_sub2tab_item last flex_justify_center en">
                        Store type, Interior, Customer
                    </li>
                </template>

                <template #tab2_sub2tab_item1>
                    <li class="tab2_sub2tab_item flex_column flex_justify_center en">
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1 en">Interior</span>
                        <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2 en">
                            Modern 7<br />
                            Luxury 3<br />
                            Vintage 3
                        </p>
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                    </li>
                </template>

                <template #tab2_sub2tab_item2>
                    <li class="tab2_sub2tab_item flex_column flex_justify_center mlr_auto en">
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1 en">Time of day</span>
                        <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2 en">
                            Morning 5<br />
                            Afternoon 6<br />
                            Dawn 3
                        </p>
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                    </li>
                </template>
                <template #tab2_sub2tab_item3>
                    <li class="tab2_sub2tab_item flex_column flex_justify_center  mr_auto en">
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1 en">Season</span>
                        <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2 en">
                            Spring 5<br />
                            Summer 3<br />
                            Fall 3
                        </p>
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                    </li>
                </template>
                <template #tab2_sub2tab_item4>
                    <li class="tab2_sub2tab_item flex_column flex_justify_center en">
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1 en">Emotion</span>
                        <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2 en">
                            Happiness 5<br />
                            Calmness 3<br />
                            Sadness 3
                        </p>
                        <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                    </li>
                </template>

                <template #tab2_sub2tab_text1>
                    Automatic music labeling technology
                </template>

                <template #tab2_sub2tab_text1_tablet>
                    Automatic music labeling technology
                </template>

                <template #sub1tabinfogroupimg>
                    <img
                        class="tab1_sub1tab_infogroup"
                        src="/media/images/home/tab1_sub1tab_infogroup_en.png"
                        alt="tab1_sub1tab_infogroup"
                    />
                </template>

                <!-- Tablet -->
                <template #home3sectioncontents1itemsecond_tablet>
                    <div class="home3_section_contents1_item ml_auto">
                        Music data
                    </div>
                </template>

                <template #sub1tabinfogroupimgtablet>
                    <img
                        class="tab1_sub1tab_infogroup"
                        src="/media/images/home/tab1_sub1tab_infogroup_en.png"
                        alt="tab1_sub1tab_infogroup"
                    />
                </template>
            </home-section3>
        </section>
        <section class="home_section4" id="homeSection4">
            <home-section4 :isen="true">
                <template #homesectiont4title>
                    Product
                </template>

                <template #homesecton4subtitle>
                    We offer products tailored to various types of spaces,
                    <div class="home_section4_subtitle_br"></div>
                    including commercial,vehicle, and complex spaces.
                </template>

                <!--###################### Card1 ######################-->
                <template #contentstitle1>
                    For Store
                </template>

                <template #contentparagraph1>
                    Our AI automatically curates and plays the optimal music for each store in real-time, relieving store
                    operators of the burden of music selection and copyright concerns.
                </template>
                <template #contentparagraph1mo>
                    Our AI automatically curates and plays the optimal music for each store in real-time, relieving store
                    operators of the burden of music selection and copyright concerns.
                </template>

                <template #contents2card1img1>
                    <img
                        class="list_item_contents2_card1_img1"
                        src="/media/images/home/sectiont4_card1_img1_en.png"
                        alt="sectiont4_card1_img1.png"
                    />
                </template>

                <template #contents2card1texttitle1>
                    Theme channels
                </template>

                <template #contents2card1textdesc1>
                    Hourly automatic updates based on feedback
                </template>

                <template #contents2card1texttitle2>
                    Automatic player on/off
                </template>

                <template #contents2card1textdesc2>
                    Player on/off based on operation hours and break times
                </template>

                <template #contents2card1texttitle3>
                    Reflection of feedback
                </template>

                <template #contents2card1textdesc3>
                    AI gets smarter with likes and dislikes
                </template>

                <template #contents2card1texttitle4>
                    Time of day curation settings
                </template>

                <template #contents2card1textdesc4>
                    Music curation settings based on time of day, day of the week, and space characteristics
                </template>

                <template #contents2card2title>
                    Franchise management is a breeze!
                </template>

                <template #contents2card1sub1img1>
                    <img
                        class="list_item_contents2_card1_sub1img1"
                        src="/media/images/home/section4_card1_img2_en.png"
                        alt="section4_card1_img2"
                    />
                </template>

                <template #card1sub1title1>
                    Monitoring/management of multiple brands and stores
                </template>

                <template #card1sub1desc1>
                    Through our solution for managing and monitoring multiple spaces, you can check the status of music playback
                    and curation settings for franchise locations under the headquarters' brand.
                </template>
                <template #card1subdesc1tablet>
                    Through our solution for managing and monitoring multiple spaces, you can check the status of music playback
                    and curation settings for franchise locations under the headquarters' brand.
                </template>

                <template #contents2card1sub1img2>
                    <img
                        class="list_item_contents2_card1_sub1img1"
                        src="/media/images/home/section4_card1_img3_en.png"
                        alt="section4_card1_img2"
                    />
                </template>

                <template #card1sub1title2>
                    Announcement
                </template>

                <template #card1sub1desc2>
                    Offers functions to play audio content such as announcements, brand jingles, event promotions, and information
                    about specific products at the desired time set by the HQs.
                </template>
                <template #card1sub1desc2tablet>
                    Offers functions to play audio content such as announcements, brand jingles, event promotions, and information
                    about specific products at the desired time set by the HQs.
                </template>

                <!--###################### Card2 ######################-->
                <template #contentscard2img1>
                    <img
                        class="list_item_contents2_card2_img1"
                        src="/media/images/home/section4_card2_img1_en.png"
                        alt="section4_card2_img1.png"
                    />
                </template>
                <template #contentstitle2>
                    For Vehicle
                </template>
                <template #contentparagraph2>
                    Through a vehicle-optimized curation service tailored to driving environments, <br />
                    we offer music curation considering not only the driver's musical preferences but also integrates complex
                    driving data.
                </template>
                <template #contentparagraph2mo>
                    Through a vehicle-optimized curation service tailored to driving environments, we offer music curation
                    considering not only the driver's musical preferences but also integrates complex driving data.
                </template>

                <template #contents2card2texttitle1>
                    Swipe to change channels
                </template>

                <template #contents2card2textdesc1>
                    Easy channel switching while driving
                </template>

                <template #contents2card2texttitle2>
                    Music recommendation based on driving purpose
                </template>

                <template #contents2card2textdesc2>
                    Curation for various purposes such as commuting, driving, traveling
                </template>

                <template #contents2card2texttitle3>
                    Easy connection with vehicle
                </template>

                <template #contents2card2textdesc3>
                    More convenient with Android Auto and Carplay
                </template>

                <!--###################### Card3 ######################-->
                <template #contentstitle3>
                    For Building
                </template>

                <template #contentparagraph3>
                    The multi-channel integrated music solution of APLAYZ for building allows you to efficiently manage <br />
                    each spatial music with one device.
                </template>

                <template #contentparagraph3mo>
                    The multi-channel integrated music solution of APLAYZ for building allows you to efficiently manage each
                    spatial music with one device.
                </template>

                <template #contents2card3img1>
                    <figure class="list_item_contents2_card3_img1_wrap">
                        <img
                            class="list_item_contents2_card3_img1"
                            src="/media/images/home/section4_card3_img1_en.png"
                            alt="section4_card3_img1.png"
                        />
                    </figure>
                </template>
                <template #contents2card3img1tablet>
                    <figure class="list_item_contents2_card3_img1_wrap_tablet">
                        <img
                            class="list_item_contents2_card3_img1"
                            src="/media/images/home/section4_card3_img1_en_tablet.png"
                            alt="section4_card3_img1.png"
                        />
                    </figure>
                </template>

                <template #contents3card1texttitle1>
                    Integrated management of music playback by space
                </template>

                <template #contents3card1textdesc1>
                    Player control(play/stop) by space, automatic player on/off
                </template>

                <template #contents3card1texttitle2>
                    Integration with in-store equipment
                </template>

                <template #contents3card1textdesc2>
                    Integration with existing in-store equipment to address building fire safety regulations
                </template>
            </home-section4>
        </section>
        <section class="home_section5" id="homeSection5">
            <home-section5 :isen="true" :slide1list="section5slide1list" :slide2list="section5slide2list">
                <template #homesection5title>
                    Client
                </template>

                <template #homesection5paragraph>
                    Major corporations and well-known domestic franchise companies are partnering with APLAYZ <br />
                    to play music optimized for their brand image.
                </template>

                <template #homesection5paragraphmo>
                    Major corporations and well-known domestic franchise companies are partnering with APLAYZ to play music
                    optimized for their brand image.
                </template>

                <template #homesection5tabitem1>
                    For Store
                </template>

                <template #homesection5tabitem2>
                    For Building
                </template>
            </home-section5>
        </section>
        <section class="home_section6">
            <home-section6 :isen="false" @open-inquiry="isOpenHomeModal = true">
                <template #section6title>
                    History
                </template>

                <template #section6subtitle>
                    <h2 class="home_section6_subtitle1 wow animate__animated animate__aplayzFadeInUp " data-wow-offset="35">
                        APLAYZ, a spin-off startup from Hyundai Motor Group(HMG), <br class="home_section6_subtitle1_br" />
                        is leading innovation in the AI-powered spatial music market.
                    </h2>
                </template>

                <template #boxmonth1>
                    June
                </template>

                <template #boxmonthhistory1>
                    Selected for TIPS (Tech Incubator Program for Startups) Startup Commercialization and Overseas Marketing
                    Support Program(Government funded)
                </template>

                <template #boxmonth2>
                    May
                </template>

                <template #boxmonthhistory2>
                    Selected for Hyundai Construction x Seoul Startup Open Innovation program <br />
                    Selected for Public-Private Partnership Open Innovation - Hanwha Hotels&Resorts <br />
                    Selected for Deep Tech Hub company at Seoul Startup Hub, Gongdeok <br />
                    NDA signed with NextPayments and collaboration on PoC product development
                </template>

                <template #boxmonth3>
                    January
                </template>

                <template #boxmonthhistory3>
                    Exhibited in CES
                </template>

                <template #boxmonth4>
                    December
                </template>

                <template #boxmonthhistory4>
                    Raised Pre-A funding(Obigo)
                </template>

                <template #boxmonth5>
                    October
                </template>

                <template #boxmonthhistory5>
                    Signed MOU with OBIGO <br class="home_section6_box2_br" />
                    - Personalized in-car music service
                </template>

                <template #boxmonth6>
                    September
                </template>

                <template #boxmonthhistory6>
                    Signed PoC project contract with Hanwha Vision <br class="home_section6_box2_br" />
                    - CCTV data-based curation
                </template>

                <template #boxmonth7>
                    June
                </template>

                <template #boxmonthhistory7>
                    Participated HMG Open Innovation Tech-Day <br />
                    Signed MOU with Hyundai Engineering <br class="home_section6_box2_br" />
                    - Improvement of work environments through music contents <br />
                    Participated NextRise 2023, Seoul exhibition
                </template>

                <template #boxmonth8>
                    May
                </template>

                <template #boxmonthhistory8>
                    Selected as provider for small business <br class="home_section6_box2_br" />
                    owner capacity strengthening government project <br />
                    Selected as TIPS R&D program(Government funded) <br />
                    Selected as initial start-up package program(Government funded)
                </template>

                <template #boxmonth9>
                    April
                </template>

                <template #boxmonthhistory9>
                    Certified as venture company
                </template>

                <template #boxmonth10>
                    January
                </template>

                <template #boxmonthhistory10>
                    Raised Seed funding(HMG Zero One No.2 Fund) <br />
                    Spin-off and establishment of independent corporation
                </template>

                <template #boxmonth11>
                    October
                </template>

                <template #boxmonthhistory11>
                    Approved for spin-off
                </template>

                <template #boxmonth12>
                    August
                </template>

                <template #boxmonthhistory12>
                    Official launch of APLAYZ service
                </template>

                <template #boxmonth13>
                    June
                </template>

                <template #boxmonthhistory13>
                    Established APLAYZ Inc. as a pilot company
                </template>

                <template #boxmonth14>
                    May
                </template>

                <template #boxmonthhistory14>
                    Launched Beta service
                </template>

                <template #boxmonth15>
                    November
                </template>

                <template #boxmonthhistory15>
                    Selected for HMG ZER01NE Company Builder
                </template>

                <template #closebtn>
                    See less
                </template>

                <template #morebtn>
                    See more
                </template>

                <template #section6infotitle>
                    Enhance the value of space with APLAYZ.
                </template>

                <template #section6infobtn>
                    Contact
                </template>
            </home-section6>
        </section>
        <home-footer :isen="true">
            <template #infoitem1>
                Company: APLAYZ Inc.
            </template>
            <template #infoitem2>
                CEO: Jungjin Bae
            </template>
            <template #infoitem3>
                Address: 21, Baekbeom-ro 31-gil, Mapo-gu, Seoul, Republic of Korea
            </template>

            <template #contactitem1>
                Inquiry: contact@aplayz.co.kr
            </template>

            <template #socialmediatext1>
                Social Media
            </template>
        </home-footer>
    </div>
</template>
<script>
import { WOW } from 'wowjs';

export default {
  name: 'ReNewalHomeEn',
  components: {
    'home-header': () => import(`@/components/home/HomeHeader.vue`),
    'home-section1': () => import(`@/components/home/HomeSection1.vue`),
    'home-section2': () => import(`@/components/home/HomeSection2.vue`),
    'home-section3': () => import(`@/components/home/HomeSection3.vue`),
    'home-section4': () => import(`@/components/home/HomeSection4.vue`),
    'home-section5': () => import(`@/components/home/HomeSection5.vue`),
    'home-section6': () => import(`@/components/home/HomeSection6.vue`),
    'home-footer': () => import(`@/components/home/HomeFooter.vue`),
    'home-modal': () => import(`@/components/home/HomeModal.vue`)
  },
  data () {
    return {
      wow: null,
      isOpenHomeModal: false,
      activeIndex: 0,
      section5slide1list: [
        {
          text: 'Terarosa', // 테라로사
          imgUrl: '/media/images/home/tab1_slide1.png',
          brandImg: '/media/images/home/Terarosa_brandimg.png'
        },
        {
          text: 'ASHLEY QUEENS',
          imgUrl: '/media/images/home/tab1_slide2.png',
          brandImg: '/media/images/home/ASHLEY_brandimg.png'
        },
        {
          text: 'Hyundai Motors showrooms and service centers',
          imgUrl: '/media/images/home/tab1_slide3.png',
          brandImg: '/media/images/home/Hyundai_brandimg.png'
        },
        {
          text: 'Haevichi Hotel&Resort',
          imgUrl: '/media/images/home/tab1_slide4.png',
          brandImg: '/media/images/home/Haevichi_brandimg.png'
        },
        {
          text: 'LIVART',
          imgUrl: '/media/images/home/tab1_slide5.png',
          brandImg: '/media/images/home/LIVART_brandimg.png'
        },
        {
          text: `Yeokjeon Grandma's beer`,
          imgUrl: '/media/images/home/tab1_slide6.png',
          brandImg: '/media/images/home/Yeokjeon_brandimg.png'
        }
      ],
      section5slide2list: [
        {
          text: 'Hyundai Engineering HQ',
          imgUrl: '/media/images/home/tab2_slide1.png',
          brandImg: '/media/images/home/Engineering_brandimg.png'
        },
        {
          text: 'SK gas charging station',
          imgUrl: '/media/images/home/tab2_slide2.png',
          brandImg: '/media/images/home/skgas_brandimg.png'
        },
        {
          text: 'Factorial Seongsu',
          imgUrl: '/media/images/home/tab2_slide3.png',
          brandImg: '/media/images/home/Factorial_brandimg.png'
        },
        {
          text: 'Hyundai Capital HQ and business locations',
          imgUrl: '/media/images/home/tab2_slide4.png',
          brandImg: '/media/images/home/Capital_brandimg.png'
        },
        {
          text: 'Hyundai Steel business locations',
          imgUrl: '/media/images/home/tab2_slide5.png',
          brandImg: '/media/images/home/Steel_brandimg.png'
        },
        {
          text: 'Hyundai Motors HQ/business locations/research centers',
          imgUrl: '/media/images/home/tab2_slide6.png',
          brandImg: '/media/images/home/Hyundai_brandimg.png'
        }
      ]
    };
  },

  mounted () {
    this.wow = new WOW({ live: false });
    this.wow.init();
    this.wow.sync();

    this.$nextTick(() => {
      if (Object.keys(this.$route.params).length > 0) {
        this.onClickNav(this.$route.params);
        const { index } = this.$route.params;
        this.activeIndex = index;
      }
      window.addEventListener('scroll', this.onScrollEvent);
    });
  },

  methods: {
    onScrollEvent () {
      const currentScrollY = window.scrollY + 100;
      // section1의 범위
      const section1Start = 0;
      const section1End = document.querySelector('.home_section1').clientHeight;

      // section2의 범위
      const section2Start = document.querySelector('.home_section2').offsetTop;
      const section2End = section2Start + document.querySelector('.home_section2').clientHeight;

      // section3의 범위
      const section3Start = document.querySelector('.home_section3').offsetTop;
      const section3End = section3Start + document.querySelector('.home_section3').clientHeight;

      // section4의 범위
      const section4Start = document.querySelector('.home_section4').offsetTop;
      const section4End = section4Start + document.querySelector('.home_section4').clientHeight;

      // section5의 범위
      const section5Start = document.querySelector('.home_section5').offsetTop;
      const section5End = section5Start + document.querySelector('.home_container').clientHeight;

      if (section1Start < currentScrollY && section1End > currentScrollY) {
        this.activeIndex = 0;
      } else if (section2Start < currentScrollY && section2End > currentScrollY) {
        this.activeIndex = 0;
      } else if (section3Start < currentScrollY && section3End > currentScrollY) {
        this.activeIndex = 1;
      } else if (section4Start < currentScrollY && section4End > currentScrollY) {
        this.activeIndex = 2;
      } else if (section5Start < currentScrollY && section5End > currentScrollY) {
        this.activeIndex = 3;
      }
    },
    onOpenInquiry () {
      this.isOpenHomeModal = true;
    },
    onClickGoPage (name) {
      this.$router.push({ name }).catch(() => {});
    },
    onClickNav (params) {
      const { id } = params;
      if (id) {
        const ele = document.querySelector(`#${id}`);
        if (ele) {
          ele.scrollIntoView({
            block: 'start'
          });
          this.isOpenMobileNav = false;
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    onCloseHomeModal () {
      this.isOpenHomeModal = false;
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScrollEvent);
  }
};
</script>
<style src="@/assets/css/home/homefont.css"></style>
<style>
.animate__aplayzFadeInUp {
  animation-name: aplayzFadeInUp;
}

@keyframes aplayzFadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
<style scoped src="@/assets/css/home/renewalhome.css"></style>
